import React, { useEffect,useState } from 'react';
import { ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import './ThemeToggle.css';

function ThemeToggle({ theme, setTheme }) {
    const [value, setValue] = useState(theme === 'light' ? 1 : 2);

    const handleChange = (val) => {
        setValue(val);
        setTheme(val === 1 ? 'light' : 'dark');
    };

    useEffect(() => {
        setValue(theme === 'light' ? 1 : 2);
    }, [theme]);

    return (
        <ToggleButtonGroup type="radio" name="options" value={value} onChange={handleChange}>
            <ToggleButton id="tbg-radio-1" value={1} className={`custom-toggle-btn ${value === 1 ? 'selected' : ''}`}>
                <i className="fa-regular fa-sun"></i>
            </ToggleButton>
            <ToggleButton id="tbg-radio-2" value={2} className={`custom-toggle-btn ${value === 2 ? 'selected' : ''}`}>
                <i className="fa-regular fa-moon"></i>
            </ToggleButton>
        </ToggleButtonGroup>
    );
}

export default ThemeToggle;
