import React, { useEffect } from 'react';
import { RemoveScrollBar } from 'react-remove-scroll-bar';
import { Modal, Row, Col } from 'react-bootstrap';
import { useTheme } from '../Theme';
import './AboutPop.css';
// function AboutPop(props) {

//     return (props.trigger) ? (
// <div className="popup">
//     <RemoveScrollBar />
//     <div className="text-container">
//         <div className="text-facts">
//         <p><h3>Name:</h3>
//         <p>Richard "Graedon" Beeler</p>
//         <h3>Age:</h3>
//         <p>20</p>
//         <h3>School:</h3>
//         <p>Chapman University</p>
//         <h3>Major:</h3>
//         <p>Software Engineering</p>
//         </p>
//         <h3>Skills:</h3>
//         <ul>
//             <li>Data Structures and Algorithms</li>
//             <li>Object Oriented Programming</li>
//             <ul>
//                 <li>C++</li>
//                 <li>Java</li>
//                 <li>Python</li>
//                 <li>C#</li>
//             </ul>
//             <li>Data Science/Machine Learning</li>
//             <li>Frontend Development (React)</li>
//             <li>CAD Software</li>
//             <li>Teamwork</li>
//             <li>Problem Solving</li>
//             <li>Communication</li>
//             <li>WSL,Linux/Unix</li>
//             <li>Docker</li>
//             <li>Git</li>
//         </ul>
//         </div>
//         <div className='text-paragraph'>
//         <p><h3>Background:</h3>I am currently a Sophomore at Chapman University, planning on graduating in Fall of 2025. I am majoring in Software Engineering and minoring in Economics. Some of my passions include running, working out, watching sports, playing video games, playing guitar and bass, and my faith in Jesus Christ. <br/><br/>I began this portfolio as a project for a Human Factors class which covers effective web design, and decided to create it from scratch using React. I hope to continue adding new projects and updates as I grow as a software developer.</p>
//         </div>
//         <div className="download-link"> <a href="/public/other/Richard Graedon Beeler Resume 2024 (3).pdf" download="beeler_resume.pdf">Download My Resume</a> </div>
//         <button className="close-btn" onClick={() => props.setTrigger(false)}><i className="fa-solid fa-circle-xmark"></i></button>
//         {props.children}
//     </div>



// </div>
//     ) : "";
// }
const AboutPop = ({ showMore, handleClose }) => {
    const { theme } = useTheme();

    useEffect(() => {
        document.documentElement.setAttribute('data-theme', theme);
    }, [theme]);
    return (
        <div data-theme={theme}>
            <Modal size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered show={showMore} onHide={handleClose}>
                <Modal.Header className="about-pop-head" closeButton={false}>
                    <Modal.Title>About Me</Modal.Title>
                    <button className="close-btn ms-auto" onClick={handleClose}><i className="fa-solid fa-circle-xmark"></i></button>
                </Modal.Header>
                <Modal.Body className="about-pop-body">
                    <Row>
                        <Col xs={12} md={6} lg={4}>
                            <h3>Name:</h3>
                            <p className="pop-p">Richard "Graedon" Beeler</p>
                        </Col>
                        <Col xs={12} md={6} lg={4}>
                            <h3>Age:</h3>
                            <p className="pop-p">20</p>
                        </Col>
                        <Col xs={12} md={6} lg={4}>
                            <h3>School:</h3>
                            <p className="pop-p">Chapman University</p>
                        </Col>
                        <Col xs={12} md={6} lg={4}>
                            <h3>Major:</h3>
                            <p className="pop-p">Software Engineering</p>
                        </Col>
                        <Col xs={12} md={6} lg={4}>
                            <h3>Skills:</h3>
                            <ul>
                                <li>Data Structures and Algorithms</li>
                                <li>Object Oriented Programming
                                    <ul>
                                        <li>C++</li>
                                        <li>Java</li>
                                        <li>Python</li>
                                        <li>C#</li>
                                    </ul>
                                </li>
                                <li>Data Science/Machine Learning</li>
                                <li>Frontend Development (React)</li>
                                <li>CAD Software</li>
                                <li>Teamwork</li>
                                <li>Problem Solving</li>
                                <li>Communication</li>
                                <li>WSL, Linux/Unix</li>
                                <li>Docker</li>
                                <li>Git</li>
                            </ul>
                        </Col>

                    </Row>
                    <Row>
                        <Col><h3>Background:</h3><p className="pop-p">I am currently a Junior at Chapman University, planning on graduating in Spring of 2026. I am majoring in Software Engineering and minoring in Economics. Some of my passions include running, working out, watching sports, playing video games, playing guitar and bass, and my faith in Jesus Christ. <br /><br />I began this portfolio as a project for a Human Factors class which covers effective web design, and decided to create it from scratch using React. I hope to continue adding new projects and updates as I grow as a software developer.</p></Col>
                    </Row>
                    <Row className="justify-content-center"> <Col xs={12} className="text-center">
                        <a href="https://raw.githubusercontent.com/gbeelerr/Projects/main/Personal/PortfolioWebsite/Richard%20Graedon%20Beeler%20Resume%2024-25%20-%20Google%20Docs.pdf
" download="beeler_resume.pdf">Download My Resume</a>
                    </Col></Row>
                </Modal.Body>

            </Modal>
        </div>
    );
}

export default AboutPop;