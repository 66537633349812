import React from 'react';
import './Footer.css';
import SocialBar from './SocialBar';
import { Link } from 'react-scroll';
import { Container,Row,Col } from 'react-bootstrap';
function Footer() {
    return (
        // <footer className="footer">
        //     <div className="footer-content">
        //         <div className="left-section">
        //             <div>
        //                 <p><strong>Richard Graedon Beeler</strong></p>
        //                 <p>925-784-5807</p>
        //                 <p>beeler@chapman.edu</p>
        //             </div>
        //             <div>
        //                 <SocialBar />
        //             </div>
        //         </div> {/**/}
        //       <nav className="footer-nav"> 
        //            {/* <a href="#">Home</a> */}
        //             <a href="/" className="nav-link"><i className="fas fa-home"></i></a>
        //             <Link to="about" smooth={true} duration={500} className="nav-link">About</Link>
        //             <Link to="projects" smooth={true} duration={500} className="nav-link">Projects</Link>
        //             <Link to="contact" smooth={true} duration={500} className="nav-link">Contact</Link>

                   
        //         </nav> {/**/}

        //     </div>
        // </footer>
        <footer className="footer">
            <Container fluid className = "footer-container">
                <Row>
                    <Col sm={12} md={4} className="footer-social">
                        <p><strong>Richard Graedon Beeler</strong></p>
                        <p>925-784-2780</p>
                        <p>beeler@chapman.edu</p>
                    </Col>
                   
                    <Col sm={12} md={4} className="text-center footer-nav-wrapper" >
                    <nav className="footer-nav"> 
                     
                            <a href="/" className="nav-link"><i className="fas fa-home"></i></a> 
                            
                           {/* <Col>
                                <Link to="about" smooth={true} duration={500} className="nav-link">About</Link>
                            </Col>
                            <Col>
                                 <Link to="projects" smooth={true} duration={500} className="nav-link">Projects</Link>
                            </Col>
                            <Col>
                                <Link to="contact" smooth={true} duration={500} className="nav-link">Contact</Link>
                            </Col>  */}
                     </nav>
                    

                    </Col> 
                    <Col sm={12} md={4}>
                        <SocialBar />
                        {/* <SocialBar xs="auto" className="d-flex justify-content-center" /> */}

                    </Col>
                </Row>
            </Container>
        </footer>
    );
}

export default Footer;