import React, { useState, useEffect } from 'react';
import { Container, Card, Row, Col, Button } from 'react-bootstrap';
import AboutPop from './AboutPop';
import './About.css';

function About({theme}) {
    const [showMore, setShowMore] = useState(false);
    const handleShow= ()=> setShowMore(true);
    const handleClose = () => setShowMore(false);

    return (
        <div id="about" className="about-container" data-theme={theme}>
        {/*     <div className="about-header">
              <h1>About Me</h1>
           </div> */}
            {/* <Container> */}
            <Card
                className="mx-auto shadow custom-card"
                style={{
                    maxWidth: '1000px',
                    minWidth: '350px',
                }}
            >
                    <Card.Header className="about-header"><h2 className="fw-bold">About Me</h2></Card.Header>
                    <Card.Title className="fs-5">Hi, I'm Graedon, an aspiring Software Engineer</Card.Title>
                {/* <Card.Subtitle className="">Hi, I'm Graedon, an aspiring Software Engineer</Card.Subtitle> */}
                    <Row noGutters>
                        <Col className = "image-column" md={6} sm={12}>
                            <Card.Img src="/images/IMG_0309square.jpg" alt="About" className="img-fluid rounded-circle" />
                        </Col>
                        <Col md={6} sm={12} className="d-flex align-items-center">
                            <Card.Body>
                                <Button className="show-button" onClick={() => setShowMore(true)}>
                                    About <i className="fa-solid fa-angles-right"></i>
                                </Button>
                                <AboutPop theme={theme} showMore={showMore} handleClose={handleClose} />
                            </Card.Body>
                        </Col>
                    </Row>
                </Card>
            {/* </Container> */}
        </div>
    );
}

export default About;
