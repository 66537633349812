import React from 'react';
import { Link } from 'react-scroll';
import ThemeToggle from './ThemeToggle';
import { Navbar, Nav, Container, NavDropdown } from 'react-bootstrap';
import './Header.css';

function Header({theme,setTheme}) {
    return (
        // <header className="header">
        //     <div className="logo">
        //         <a href="#">
        //        {/* <img src="/images/Richard Graedio.png" alt="Logo" className="logo-style"/>*/}
        //         <img src="/images/Logo maker project.png" alt="Logo" className="logo-style" />
        //         </a>
        //     </div>
        //     <nav className="navigation">
        //        {/* <button className="nav-button">Home</button>*/}
        //         <Link to="about" smooth={true} duration={500} className="nav-link">About</Link>
        //         <Link to="projects" smooth={true} duration={500} className="nav-link">Projects</Link>
        //         <Link to="contact" smooth={true} duration={500} className="nav-link">Contact</Link>
        //     </nav>
        //     {/* <div className="home">
        //         <a href="#" className="home-icon">
        //     <i className="fas fa-home"></i>
        //         </a>
        //     </div> */}
        //     <ThemeToggle theme={theme} setTheme={setTheme}/>
        // </header>
        <header className="header">
            <Navbar collapseOnSelect expand="lg" bg={theme === 'dark' ? 'dark' : 'light'} variant={theme === 'dark' ? 'dark' : 'light'} className="w-100 the-nav-bar">
                <Container fluid className="navbar-container">
                    <Navbar.Brand href="#">
                        <img src="/images/Logo maker project.png" alt="Logo" className="logo-style" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-between">
                        <Nav className="nav-links mx-auto">
                            <Nav.Link as={Link} to="about" smooth={true} duration={500} className="nav-link">About</Nav.Link>
                            <Nav.Link as={Link} to="projects" smooth={true} duration={500} className="nav-link">Projects</Nav.Link>
                            <Nav.Link as={Link} to="contact" smooth={true} duration={500} className="nav-link">Contact</Nav.Link>
                        </Nav>
                        <ThemeToggle theme={theme} setTheme={setTheme} className="theme-toggle" />
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    );
}

export default Header;
